/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/theming';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

//* {
//
//}

body {
  background-color: #b9d67d;
  color: #002d1a;
  margin: 1em;
  font-family: MetaWebPro-Normal, sans-serif !important;
}

.mat-grid-tile-content{
  top: auto !important;
  left: auto !important;
  right: auto !important;
  bottom: auto !important;
  position: relative !important;
  display: block !important;
  height: 100%;
  padding: 0;
  margin: 0;
}

.main-area{
  margin-top: 1rem;

  .main-container {
    -webkit-box-shadow: 0.5em 0.5em 1em #333c23;
    -moz-box-shadow: .5em .5em 1em #333c23;
    box-shadow: 0.5em 0.5em 1em #333c23;
    background: white;
    padding: 1rem;
  }
}

.aktion{
  cursor: pointer;
  margin-right: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: white;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #b9d67d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #deff6e;
}

.mat-focused .mat-form-field-label {
  /*change color of label*/
  color: #002d1a !important;
}

.mat-form-field-underline {
  /*change color of underline*/
  background-color: #002d1a !important;
}

.mat-form-field-ripple {
  /*change color of underline when focused*/
  background-color: #002d1a !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #002d1a!important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color: #b9d67d;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle{
  color: #b9d67d;
  background-color: #b9d67d;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #deff6e;
}

.mat-checkbox .mat-checkbox-frame {
  border-color: #b9d67d!important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #b9d67d!important;
}

.mat-tab-body-wrapper{
  height: 1440px !important;
  flex-grow: 1;
}

/* active tab */
.mat-tab-list .mat-tab-labels :hover {
  background-color: #deff6e!important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #002d1a!important;
}

/* active tab */
.mat-tab-list .mat-tab-labels :hover {
  background-color: #deff6e!important;
}

/* ink bar */
.mat-ink-bar {
  background-color: #b9d67d!important;
}

.mat-pseudo-checkbox-checked{
  background-color: #b9d67d!important;
}


@media print {
  body{
    margin: 0!important;
    padding: 0!important;
  }
  header{
    display: none;
  }
  .main-area{
    margin: 0!important;
  }
  .side-container {
    display: none;
  }
  .main-container{
    margin: 0!important;
  }
}
